import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import { formatInlineCssProperty } from 'client/site-modules/industry-center/utils/utils';
import { CSS_PROPERTY_CONSTANTS } from 'client/site-modules/industry-center/utils/constants';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import './section-cta.scss';

export function SectionCta({ entry }) {
  if (!entry) {
    return null;
  }
  const contentEntries = entry.getAllMetadata();
  if (!(contentEntries && Object.keys(contentEntries).length)) {
    return null;
  }
  const {
    button,
    'header-text': headerText,
    'sub-text': subText,
    'button-url': ctaUrl,
    'button-title': ctaTitle,
    'button-color': btnColor,
    'text-color': textColor,
    'background-color': bgColor,
  } = contentEntries;

  return (
    <div
      className="section-cta-container d-flex flex-wrap px-2 py-3"
      style={formatInlineCssProperty(bgColor, CSS_PROPERTY_CONSTANTS.BACKGROUND_COLOR)}
    >
      <Col xs={12} md={8} className="my-1 my-md-0">
        {headerText && (
          <ContentFragment
            componentToUse={'div'}
            classes="display-4 fw-bold"
            style={formatInlineCssProperty(textColor, CSS_PROPERTY_CONSTANTS.TEXT_COLOR)}
          >
            {headerText}
          </ContentFragment>
        )}
        {subText && (
          <ContentFragment
            componentToUse={'div'}
            classes="display-5 fw-bold"
            style={formatInlineCssProperty(textColor, CSS_PROPERTY_CONSTANTS.TEXT_COLOR)}
          >
            {subText}
          </ContentFragment>
        )}
      </Col>
      <Col xs={12} md={4} className="text-md-end">
        {button === 'true' && ctaTitle && (
          <Button
            className="cta-btn mt-0_5"
            href={ctaUrl}
            style={formatInlineCssProperty(btnColor, CSS_PROPERTY_CONSTANTS.BACKGROUND_COLOR)}
          >
            <span className="text-white">{ctaTitle}</span>
          </Button>
        )}
      </Col>
    </div>
  );
}

SectionCta.propTypes = {
  entry: PropTypes.shape({}),
};

SectionCta.defaultProps = {
  entry: null,
};
