import React from 'react';
import PropTypes from 'prop-types';
import { pick, isEmpty } from 'lodash';
import Col from 'reactstrap/lib/Col';
import { Link } from 'site-modules/shared/components/link/link';
import { STORIES_INFO_MAP } from './story-info-box-constants-map';

import './story-info-box.scss';

export function ShowStoryInfoBox({ entry }) {
  const { id } = entry;
  if (id !== 'rich-text') return null;

  const { 'header-text': headerText } = entry.getAllMetadata();
  const info = pick(STORIES_INFO_MAP, headerText)[headerText];
  if (isEmpty(info)) return null;
  return (
    <Col className="d-flex justify-content-start pb-1">
      {info.mr && (
        <Link to={info.mr.href} className="pr-1 fw-bold info-box-link" target="_blank">
          {info.mr.title}
        </Link>
      )}
      <Link to={info.story.href} className="pr-1 fw-bold info-box-link" target="_blank">
        {info.story.title}
      </Link>
    </Col>
  );
}

ShowStoryInfoBox.propTypes = {
  entry: PropTypes.shape({}),
};

ShowStoryInfoBox.defaultProps = {
  entry: {},
};
