import React from 'react';
import PropTypes from 'prop-types';
import { get, merge } from 'lodash';
import { formatInlineCssProperty } from 'client/site-modules/industry-center/utils/utils';
import { CSS_PROPERTY_CONSTANTS } from 'client/site-modules/industry-center/utils/constants';

export function TestimonialSlide({ slideItem }) {
  const {
    'testimonial-text': testimonialText,
    'text-color': textColor,
    'testimonial-info': testimonialInfo,
    'background-color': backgroundColor,
    'testimonial-name': testimonialName,
  } = slideItem;

  const bgImage = get(slideItem, 'image', '');
  return (
    <div
      className="testimonial photo px-3 h-100 text-center d-flex flex-column justify-content-center align-items-center"
      style={merge(
        formatInlineCssProperty(bgImage, CSS_PROPERTY_CONSTANTS.BACKGROUND_IMAGE),
        formatInlineCssProperty(textColor, CSS_PROPERTY_CONSTANTS.TEXT_COLOR),
        formatInlineCssProperty(backgroundColor, CSS_PROPERTY_CONSTANTS.BACKGROUND_COLOR)
      )}
    >
      <div className="h3 blockquote">{testimonialText}</div>
      <div className="h4 fw-bold">{testimonialName}</div>
      <div className="fst-italic">{testimonialInfo}</div>
    </div>
  );
}

TestimonialSlide.propTypes = {
  slideItem: PropTypes.shape({}),
};

TestimonialSlide.defaultProps = {
  slideItem: {},
};
