import React, { Component, Fragment } from 'react';
import { CmsEntities } from 'client/data/models/cms';
import { injectScript } from 'client/utils/inject-script';
import { logger } from 'client/utils/isomorphic-logger';
import {
  TRADE_IN_TOOL_CONTAINER_SCRIPT_SRC,
  TRADE_IN_TOOL_TARGET_CLASS,
  DEMO_DEALER_ID,
  DEMO_CONFIG_MAKE,
} from './constants';

export class TradeInToolDemo extends Component {
  static propTypes = {
    entry: CmsEntities.Content,
  };

  static defaultProps = {
    entry: null,
  };

  componentDidMount() {
    injectScript(TRADE_IN_TOOL_CONTAINER_SCRIPT_SRC)
      .then(() => logger('log', 'Trade-In Tool script injected successfully'))
      .catch(() => logger('warn', 'Trade-In script inject error'));
  }

  render() {
    const { entry } = this.props;
    if (!entry) {
      return null;
    }

    const contentEntries = entry.getAllMetadata();
    if (!contentEntries || !Object.keys(contentEntries).length) {
      return null;
    }

    const { demoTitle, displayAttribute } = contentEntries;

    return (
      <Fragment>
        {demoTitle && <div className="display-2 text-start text-md-center text-gray-darker">{demoTitle}</div>}
        <div
          className={TRADE_IN_TOOL_TARGET_CLASS}
          data-dealerid={DEMO_DEALER_ID}
          data-configmake={DEMO_CONFIG_MAKE}
          data-display={displayAttribute}
        />
      </Fragment>
    );
  }
}
