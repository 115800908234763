import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import './sub-navigation-base.scss';

const ITEM_CLASS_NAME = 'nav-link pt-0_5 medium fw-bold';

export function SubNavigationBase({ active, list }) {
  if (!get(list, 'length')) {
    return null;
  }

  return (
    <div className="sub-navigation" data-tracking-parent="edm-entry-subnav">
      <nav
        className="pos-r navbar navbar-full navbar-inverse bg-primary p-0 text-center"
        id="subnav"
        aria-label="subnavigation"
      >
        <div className="nav-scrollable-wrapper tabs-corner-gradient navbar-full">
          <ul className="nav py-0 pl-0_5 navbar-nav h5 mx-auto nav-inline d-block" data-tracking-id="view_content">
            {list
              .filter(({ href }) => href)
              .map(({ name, href, noFollow, tracking }, index) => (
                <li key={name} className={classnames('nav-item d-inline-block px-1', { 'ml-1': index !== 0 })}>
                  {!!active && name === active ? (
                    <span className={classnames(ITEM_CLASS_NAME, 'active')}>{name}</span>
                  ) : (
                    <a
                      href={href}
                      data-tracking-value={get(tracking, 'value', name)}
                      className={ITEM_CLASS_NAME}
                      rel={noFollow ? 'nofollow' : ''}
                    >
                      {name}
                    </a>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </nav>
    </div>
  );
}

SubNavigationBase.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
      noFollow: PropTypes.bool,
    })
  ),
  active: PropTypes.string,
};

SubNavigationBase.defaultProps = {
  list: null,
  active: null,
};
